<template>
    <div class="page-warp gray-page min-page">
        <van-tabs v-model="active" @click="resetLoad()">
            <van-tab title="全部"></van-tab>
            <van-tab title="申报中" name="1"></van-tab>
            <van-tab title="待提现" name="2"></van-tab>
            <van-tab title="支付中" name="3"></van-tab>
            <van-tab title="已支付" name="4"></van-tab>
        </van-tabs>
        <div class="scroll-box">
             <div class="row loop-list mt-10"  v-for="(item,inx) in loopList" :key="inx">
                <div class="row loop-title" @click="todetail(item.id)"><span class="nums">申报单号：{{item.billNo}}</span><span class="btns">{{cms[item.commissionStatus]}}</span></div>
                <div class="row loop-cons right-jt" @click="todetail(item.id)">
                    <span class="fz-12">{{item.companyName}}</span>
                    <span class="fz-12">纳税所属期：{{item.declareMonth}}</span>
                    <span class="fz-12">纳税总金额：{{item.totalAmount}}元</span>
                    <span class="fz-12">返佣比例：{{item.commissionRateStr}}</span>
                    <span class="fz-12">返佣金额（应付）：{{item.commissionAmount}}元</span>
                    <span class="fz-12" v-if="item.commissionStatus == 4" >返佣金额（实付）：{{item.commissionAmountActually}}元</span>
                </div>
                <div class="row loop-bottom cons-btn" v-if="item.commissionStatus == 2">
                    <div class="o-b-btn ons" @click="toDoing(item)">申请提现</div>
                </div>
            </div>
            <infinite-loading 
                v-if="!finished" 
                @infinite="onLoad"
                ref="infiniteLoading">
            </infinite-loading>
            <div v-else class="row no-datas">没有更多了</div>
        </div>
    </div>
</template>
<script>
import { pageQuery,withdrawDiscount } from "@/api/discount"
import InfiniteLoading from "vue-infinite-loading";
export default {
    data() {
        return {
            active:'',
            loading: false,
            finished: false,
            page:1,
            pageSize:10,
            loopList:[],
            cms:{
                1: '申报中',
                2: '待提现',
                3: '支付中',
                4: '已支付'
            }
        };
    },
    components: {
        InfiniteLoading
    },
    methods: {
        resetLoad(){
            this.loopList = [];
            this.page = 1;
            this.loading = false;
            this.finished = false;
            this.onLoad()
        },
        todetail(res){
            this.$router.push({
            path:'/discountDetail',
                query:{
                    id:res
                }
            })
        },
        async onLoad($state) {
            if(this.loading) return false;
            // 异步更新数据
            let pramas={
                pageNum:this.page,
                pageSize:this.pageSize
            }
            if(this.active){
                pramas.commissionStatus = this.active;
            }
            this.loading = true;
            await pageQuery (pramas).then(res=>{
                this.loopList = this.loopList.concat(res.rows);
                if (res.total <= this.loopList.length) {
                    this.finished = true;
                }
                this.page +=1
                setTimeout(()=>{
                    this.loading = false;
                    
                    if(this.$refs.infiniteLoading) this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded'); //完成加载
                },1000)
            })
        },
        toDoing(row){
            let that = this;
            this.$dialog.confirm({
                message: '是否申请提现',
                beforeClose:function(action,done){
                    if (action === 'confirm') {
                        let where = {
                            id:row.id,
                            commissionAmountActually:row.commissionAmountActually
                        }
                        withdrawDiscount(where).then(res=>{
                            that.resetLoad();
                            this.$toast.loading(res.msg);
                            done();
                        }).catch(err => {
                            this.$toast.fail(err);
                            done();
                        });
                    }else{
                        done();
                    }                    
                }
            });
        }
    },
};
</script>
<style scope lang="less">
</style>